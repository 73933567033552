<template>
  <div>
    <div class="adsWrap">
      <!-- {{ videosList }} -->
      <div class="leftLine">
        <el-steps
          direction="vertical"
          :active="activeStep"
        >
          <el-step
            title="广告系列"
            @click.native="setStep(1)"
          ></el-step>
          <el-step
            title="选择受众"
            @click.native="setStep(2)"
          ></el-step>
          <el-step
            title="创意与投放位"
            @click.native="setStep(3)"
          ></el-step>
          <el-step
            title="跟踪与批量创建"
            @click.native="setStep(4)"
          ></el-step>
        </el-steps>
        <!-- <el-timeline>
                <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                >
                {{activity.content}}
                </el-timeline-item>
            </el-timeline> -->
        <el-button
          type="primary"
          size="small"
          @click="closeCreateStandardAd"
          class="close"
          >关闭</el-button
        >
      </div>
      <div class="rightCon">
        <div>
          <ad-series
            v-show="activeStep == 1"
            :creatAdSet="creatAdSet"
            :campaignInfo="campaignInfo"
            @adSeriesMsg="adSeriesMsg"
            ref="adSeries"
            :accountId="accountId"
            :initData="initData"
            :pixelList="pixelList"
            @getPixels="getPixels"
          ></ad-series>
          <audience
            v-show="activeStep == 2"
            :accountId="accountId"
            :initData="initData"
            :adSeriesInfo="adSeriesInfo"
            @audienceMsg="audienceMsg"
            ref="audience"
          ></audience>
          <ads
            v-show="activeStep == 3"
            :accountId="accountId"
            :adSeriesInfo="adSeriesInfo"
            ref="ads"
            @setAds="setAds"
            :pixelList="pixelList"
            :activeStep="activeStep"
          ></ads>
          <track-creat
            v-show="activeStep == 4"
            :accountId="accountId"
            :initData="initData"
            :adSeriesInfo="adSeriesInfo"
            :audienceInfo="audienceInfo"
            :adsInfo="adsInfo"
            :activeStep="activeStep"
            @getTableData="getTableData"
            @getSplitParmas="getSplitParmas"
            :pixelList="pixelList"
            :campaignInfo="campaignInfo"
            ref="trackCreat"
          ></track-creat>
          <!-- <track-creat2 v-show="(activeStep==4)" :accountId="accountId" :initData="initData" :adSeriesInfo="adSeriesInfo" :audienceInfo="audienceInfo" :adsInfo="adsInfo" :activeStep="activeStep" @getTableData="getTableData" @getSplitParmas="getSplitParmas" :pixelList="pixelList" :campaignInfo="campaignInfo" v-if="creatAdSet"></track-creat2> -->
        </div>
        <div class="btnGroup">
          <el-button
            type="primary"
            size="small"
            @click="prev"
            v-if="activeStep >= 2"
            >上一步</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="next"
            v-if="activeStep <= 3"
            >下一步</el-button
          >
          <el-button
            size="small"
            @click="openNewTab"
            v-if="activeStep == 3"
            class="creatNew"
            >创建新系列</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="save"
            v-if="activeStep == 4"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <!-- 确认提交弹窗 -->
    <el-dialog
      title="确认要提交吗?"
      :visible.sync="creatForm"
      width="600px"
      append-to-body
      :modal-append-to-body="false"
    >
      <div v-if="creatForm">
        <el-form
          :model="params"
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="是否立即上传到Facebook："
            prop="resource"
          >
            <el-switch
              v-model="params.is_upload"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="上传之后广告的投放状态："
            prop="name"
          >
            <el-switch
              v-model="params.campaign.status"
              active-value="ACTIVE"
              inactive-value="PAUSED"
            >
            </el-switch>
            <!-- <span style="margin-left:15px">单次转化量费用</span> -->
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="creatForm = false">取 消</el-button>
        <el-button
          :disabled="!canClick"
          type="primary"
          @click="creat"
          id="creatAdEvent"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import checkAccountStatus from '@/assets/js/checkAccountStatus.js';
import adSeries from './components/adSeries';
import audience from './components/audience';
import ads from './components/ads';
import trackCreat from './components/trackCreat';
import trackCreat2 from './components/trackCreat2';
import tools from './components/tools';
import { adConstsMap, create, accountPixel, getAdCacheData, setAdCacheData } from '@/api/creatAd.js';
import { mapState, mapGetters } from 'vuex';
import titleScroll from '@/utils/titleScroll';
export default {
  mixins: [checkAccountStatus, tools, titleScroll],
  data() {
    return {
      canClick: true,
      msg: '创建广告',
      activeStep: 1,
      accountId: this.$store.getters.accountId,
      adSeriesInfo: {},
      audienceInfo: [],
      adsInfo: [],
      splitParmas: {},
      tableData: {},
      fbAccountId: '',
      params: {
        is_upload: true,
      },
      creatForm: false,
      source: null,
      pixelList: [],
      creatAdSet: false,
      campaignInfo: null,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('num', ['selectedNum']),
    ...mapState('initData', ['initData']),
    freshMsgKey() {
      return this.userInfo.data.id + '_' + this.selectedNum + '_' + this.accountId;
    },
    isCustomSale() {
      return this.adSeriesInfo.smart_promotion_type !== 'AUTOMATED_SHOPPING_ADS';
    },
    // 获取当前所有创意的所有广告
    videosList() {
      let list = this.formatCreativeList(this.adsInfo, this.adSeriesInfo);

      let videoList = list.filter((v) => v.media_type == 'video');
      console.log(
        videoList,
        'e:\广告项目\tiktok-ads\public\favicon-failed.ico e:\广告项目\tiktok-ads\public\favicon-success.ico',
      );
      return videoList.length ? videoList : [];
    },
    // 有上传失败的视频
    hasFiledVideos() {
      return this.videosList.filter((v) => v.videoStatus == 'error').length ? true : false;
    },
    // 所有视频都上传成功
    allSuccessdVideos() {
      return this.videosList.every((v) => v.videoStatus == 'ready');
    },
  },
  components: {
    adSeries,
    audience,
    ads,
    trackCreat,
    trackCreat2,
  },
  created() {
    this.creatAdSet = this.$route.query.creatAdset;
    console.log(this.$route, '初始化creatAdSet', this.creatAdSet);
  },
  methods: {
    openNewTab() {
      window.open(window.location.href);
    },
    setAdCacheDataFun() {
      // 缓存当前未保存数据
      // adAccountId
      try {
        let obj = {};
        obj.ruleForm = this.$refs.adSeries.ruleForm;
        obj.ruleForm.targetIndex = this.$refs.adSeries.targetIndex;
        obj.contentList = this.$refs.audience.contentList;
        obj.adsList = this.$refs.ads.adsList;
        obj.adSet = this.$refs.trackCreat.adSet;
        console.log(obj);
        // if(obj.ruleForm.smart_promotion_type || obj.ruleForm.siteUrl || obj.ruleForm.customEventType || obj.ruleForm.sale || obj.ruleForm.site){
        //   let obj1 = {};
        //   obj1[this.freshMsgKey] = obj;
        //   let adsMsg = JSON.stringify(obj1)
        //   localStorage.setItem('creatAdsMsg',adsMsg)
        // }
        let reqeust = {
          adAccountId: this.accountId,
          catchContent: JSON.stringify(obj),
        };
        setAdCacheData(JSON.stringify(reqeust));
      } catch (err) {
        console.log(err);
      }
    },
    getAdCacheDataFun() {
      // 获取缓存的未保存的广告数据
      return new Promise((resolve, reject) => {
        let reqeust = {
          adAccountId: this.accountId,
        };
        getAdCacheData(JSON.stringify(reqeust)).then((res) => {
          if (res.code === 0) {
            resolve(res.data);
          } else {
            reject(res);
          }
        });
      });
    },
    whetherAllValid() {
      // 所有表单是否均通过验证
      let allPass = [];
      this.adsInfo.forEach((item, index) => {
        allPass.push([]);

        // 根据media_type不同而验证不同字段
        let validArr = [],
          props = ['action_type', 'message', 'web_url'];
        if (item.media_type != 'post') {
          validArr = [...props];
        } else {
          validArr = ['post_id'];
        }

        if (this.$refs.ads.$refs[`ruleForm${index}`] && this.$refs.ads.$refs[`ruleForm${index}`][0]) {
          this.$refs.ads.$refs[`ruleForm${index}`][0].validateField(validArr, (valid) => {
            // console.log("valid",valid)
            if (!valid) {
              allPass[index].push(1);
            } else {
              allPass[index].push(0);
            }
          });
        }
      });

      // console.log("allPass",allPass)
      return allPass;
    },
    disabedOperate() {
      this.checkAccountStatusGlobal(this.$store.state.num.activeAdAccount.id).then((res) => {
        if (res.code == 0) {
          let pass = res.data.check_pass;
          // 禁用
          if (!pass) {
            this.$alert('当前的账号状态为禁用，无法创建广告！', '无法创建广告', {
              confirmButtonText: '返回首页',
              showClose: false,
              callback: (action) => {
                this.$router.push({ name: 'adManagement' });
              },
            });
          }
        }
      });
    },
    cancelQuest() {
      // console.log(this.source);
      if (typeof this.source === 'function') {
        this.source(); //取消请求
      }
    },
    // 关闭创建广告
    closeCreateStandardAd() {
      // this.$confirm('确认关闭该页面吗，关闭后已操作数据将丢失, 是否继续?', '提示', {
      this.$confirm('确认关闭该页面吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // this.$router.push('/adManagement/index')
          this.setAdCacheDataFun();
          this.$refs.trackCreat.editedCampaignName = false;
          // this.$router.go(-1);
          this.$router.push({ name: 'adManagement' });
        })
        .catch(() => {
          this.$router.push({ name: 'CreateAd' });
        });
      // this.$router.go(-1);
      // this.$store.dispatch('app/openSideBar')
      // this.$router.push('/adManagement/index')
    },
    // 接收广告系列信息
    adSeriesMsg(v) {
      console.log(v);
      // console.log(v);
      this.adSeriesInfo = v;
    },
    // 接收广告受众信息
    audienceMsg(v) {
      this.audienceInfo = v;
      console.log(v);
    },
    // 接收广告创意
    setAds(v) {
      this.adsInfo = v;
    },
    // 接收广告拆分结果
    getTableData(v) {
      this.tableData = v;
    },
    // 接收拆分参数
    getSplitParmas(v) {
      // console.log(v);
      this.splitParmas = v;
    },
    getPixels(flag) {
      accountPixel({ accountId: this.accountId, force: flag ? true : false }, this).then((res) => {
        // console.log(res);
        this.pixelList = res.data;
        this.$message({
          type: 'success',
          message: '更新成功',
        });
      });
    },
    // 获取广告创建常量
    async getInitData() {
      if (!this.initData) {
        await adConstsMap().then((res) => {
          this.$store.commit('initData/setInitData', res.data);
        });
      }
      this.cancelQuest();
      await accountPixel({ accountId: this.accountId }, this).then((res) => {
        // console.log(res);
        this.pixelList = res.data;
      });
    },
    checkUrl(v) {
      let reg = /^(https?:\/\/.[^?/\s]+)/;
      let url = v.match(reg) ? v.match(reg)[0] : '';
      let web_url = this.adSeriesInfo.siteUrl;
      let url1 = web_url.match(reg)[0];
      return url1 == url;
    },
    async canNext() {
      let canNext = true;
      await this.checkAccountStatusGlobal(this.$store.state.num.activeAdAccount.id).then((res) => {
        if (res.code == 0) {
          canNext = res.data.check_pass;
        }
      });
      return canNext;
    },
    // 获取版位信息
    getPlateform() {
      let palteList = {
        facebook_positions: [],
        instagram_positions: [],
        audience_network_positions: [],
        messenger_positions: [],
      };
      let publisher_platforms = [];
      if (!this.$refs.ads.$refs.plateTree) {
        return {
          ...palteList,
          publisher_platforms: publisher_platforms,
          device_platforms: this.$refs.ads.radio == 2 ? this.$refs.ads.device_platforms : [],
        };
      }
      this.$refs.ads.$refs.plateTree.map((key, i) => {
        // console.log(key.getCheckedKeys());
        if (i == 0) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('facebook');
            if (key.getCheckedKeys().length >= 8) {
              palteList['facebook_positions'] = key.getCheckedKeys().slice(1);
            } else {
              palteList['facebook_positions'] = key.getCheckedKeys();
            }
          }
        }
        if (i == 1) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('instagram');
            if (key.getCheckedKeys().length >= 4) {
              palteList['instagram_positions'] = key.getCheckedKeys().slice(1);
            } else {
              palteList['instagram_positions'] = key.getCheckedKeys();
            }
          }
        }
        if (i == 3) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('audience_network');
            if (key.getCheckedKeys().length >= 9) {
              palteList['audience_network_positions'] = key.getCheckedKeys().slice(1);
            } else {
              palteList['audience_network_positions'] = key.getCheckedKeys();
            }
          }
        }
        if (i == 2) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('messenger');
            if (key.getCheckedKeys().length >= 4) {
              palteList['messenger_positions'] = key.getCheckedKeys().slice(1);
            } else {
              palteList['messenger_positions'] = key.getCheckedKeys();
            }
          }
        }
      });
      return {
        ...palteList,
        publisher_platforms: publisher_platforms,
        device_platforms: this.$refs.ads.device_platforms,
      };
    },
    // 获取文件后缀名
    getExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1);
    },
    // 验证广告系列信息
    validAdseries() {
      let flag = true;
      // 判断投放链接
      if (!this.adSeriesInfo.siteUrl && this.isCustomSale) {
        this.$message({
          type: 'warning',
          message: '请选择投放链接',
        });
        flag = false;
        return flag;
      }
      if (
        (this.$refs.adSeries.checkLinkStatus.level == 'error' || !this.$refs.adSeries.checkLinkStatus.level) &&
        this.isCustomSale
      ) {
        this.$message({
          type: 'warning',
          message: '域名不可用',
        });
        flag = false;
        return flag;
      }
      // 判断站点
      if (!this.adSeriesInfo.site && !this.creatAdSet) {
        this.$message({
          type: 'warning',
          message: '请选择站点',
        });
        flag = false;
        return flag;
      }
      // 判断转化事件
      this.$refs.adSeries.$refs.adSeriesForm.validate((valid) => {
        if (valid) {
          // this.activeStep += 1;
          flag = true;
        } else {
          flag = false;
        }
        return flag;
      });
      return flag;
    },

    // 下一步
    next() {
      let canNext = this.canNext();
      if (!canNext) {
        this.$message({
          type: 'info',
          message: '当前账号为禁用，无法进行下一步！',
        });
        return false;
      }
      // 广告系列信息校验
      if (this.activeStep == 1) {
        // 如果是fb主页广告
        if (this.adSeriesInfo.business == 'FACEBOOK_PAGE') {
          this.activeStep += 1;
          return false;
        }
        // 是否是基于系列创建
        if (!this.adSeriesInfo.site && !this.creatAdSet) {
          this.$message({
            type: 'warning',
            message: '请选择站点',
          });
          return false;
        }
        if (!this.adSeriesInfo.fbPixelId || !this.adSeriesInfo.customEventType) {
          this.$message({
            type: 'warning',
            message: '请选择转化事件',
          });
          return false;
        }
        if (this.creatAdSet) {
          this.activeStep += 1;
          return false;
        }
        if (this.validAdseries()) {
          this.activeStep += 1;
          return false;
        }
        return false;
      }
      // 受众信息校验
      if (this.activeStep == 2) {
        // console.log(this.audienceInfo);
        if (!this.audienceInfo.length) {
          this.$message({
            type: 'warning',
            message: '请填写完整的受众信息',
          });
          return false;
        }
        if (
          this.$refs.audience.statusData.invaild_insterest &&
          this.$refs.audience.statusData.invaild_insterest.length > 0
        ) {
          this.$notify.error('存在无效的兴趣词，请删除后再提交');
          return false;
        }
        let flag = true;
        this.audienceInfo.map((i) => {
          // console.log(i);
          if (
            i.excluded_geo_locations_places &&
            i.excluded_geo_locations_places.length &&
            !i.excluded_geo_locations_place_radius
          ) {
            this.$message({
              type: 'warning',
              message: '请填写排除乡镇公里数',
            });
            flag = false;
          }
          if (
            (!i.geo_locations || !i.geo_locations.length) &&
            (!i.geo_locations_region || !i.geo_locations_region.length) &&
            (!i.geo_locations_city || !i.geo_locations_city.length)
          ) {
            this.$message({
              type: 'warning',
              message: '请填写完整的受众信息',
            });
            flag = false;
          } else {
          }
        });
        if (flag) {
          this.activeStep += 1;
          return false;
        }
      }
      // 第三步
      if (this.activeStep == 3) {
        let flag = true;
        // 所有表单是否均通过验证-['action_type','message','web_url','post_id']
        let allPass = this.whetherAllValid();

        if (allPass.every((item) => item.every((c) => c))) {
          flag = true;
        } else {
          flag = false;
        }
        // 设置文案默认值
        // let msg = '请填写完整的广告创意',linkMsg="(网址中的域名必须与第一步推广网址中的域名保持一致)"

        let { msg } = this.validAdLists(this.formatCreativeList(this.adsInfo, this.adSeriesInfo), this.adSeriesInfo);
        flag = this.validAdLists(this.formatCreativeList(this.adsInfo, this.adSeriesInfo), this.adSeriesInfo).flag;
        if (!this.formatCreativeList(this.adsInfo, this.adSeriesInfo).length) {
          flag = false;
          msg = '请先添加创意！！';
        }
        if (flag) {
          this.activeStep += 1;
          return false;
        } else {
          if (msg) {
            this.$message({
              type: 'warning',
              message: msg,
              duration: 5000,
            });
          }
          return false;
        }
      }
      // 第四步
      if (this.activeStep == 4) {
      }
    },
    // 广告创建
    creat() {
      this.canClick = false;
      this.$showLoading();
      // console.log('创建参数',this.params);
      //   return;
      create({ ...this.params })
        .then((res) => {
          this.canClick = true;

          // console.log(res);
          this.$hideLoading();
          // this.splitBy = [];
          console.log(this.splitBy);
          if (res.code == 0) {
            this.creatForm = false;
            this.$confirm('跳转到草稿箱，或者重新创建一个广告', '提示', {
              confirmButtonText: '再创建一个',
              cancelButtonText: '回到草稿箱',
            })
              .then((res) => {
                this.$refs.trackCreat.editedCampaignName = false;
                this.activeStep = 1;
                this.$refs.adSeries.ruleForm.siteUrl = '';
                this.$refs.adSeries.ruleForm.sale = null;
                this.$refs.adSeries.ruleForm.site = null;
                this.$refs.adSeries.checkLinkStatus = {
                  message: '请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…',
                  level: 'error',
                };
                this.$refs.adSeries.$refs.adSeriesForm.clearValidate();
                this.$refs.trackCreat.clearsplitBy();
              })
              .catch((res) => {
                this.$store.commit('adActiveTab/setActiveTab', 'drafBox');
                // console.log(this.$store.getters.adActiveTab);
                this.$router.push({ name: 'adManagement' });
                // this.closeCreateStandardAd()
              });
          } else {
            this.canClick = true;
          }
        })
        .catch((res) => {
          this.canClick = true;
        });
    },
    // 上一步
    prev() {
      this.activeStep -= 1;
    },
    // 点击步骤
    setStep(v) {
      if (this.activeStep >= v) {
        this.activeStep = v;
      }
    },
    // 点击保存
    save() {
      // console.log(222);
      let canNext = this.canNext();
      if (!canNext) {
        this.$message({
          type: 'info',
          message: '当前账号为禁用，无法保存！',
        });
        return false;
      }
      let flag = true;
      let msg = '';
      let params = JSON.parse(JSON.stringify(this.splitParmas));
      params.creatives.map((v) => {
        if (v.web_url == 'https://null' || v.web_url == 'http://null') {
          v.web_url = null;
        }
      });
      console.log(this.tableData);
      this.tableData.adset.map((v) => {
        v.adset_schedule =
          v.adset_schedule && v.adset_schedule.length
            ? v.adset_schedule.map((k) => {
                return { start_minute: k.start_minute, end_minute: k.end_minute, days: k.days };
              })
            : [];
      });
      params.splitResult = { ...this.tableData };
      params.createFromCampaignId = this.$route.query.campaignId;
      params.splitResult.campaign_name = this.$route.query.campaignName
        ? this.$route.query.campaignName
        : params.splitResult.campaign_name;
      params.splitResult.adset.map((item) => {
        item.status = item.status ? item.status : null;
        // if (item.users < 1000) {
        //   flag = false;
        //   msg = "受众规模不得少于1000";
        // }
        console.log(params);
        if (params.adsets.pacing_type == 'day_parting' && !item.adset_schedule.length) {
          flag = false;
          msg = '您已选择分时段投放，请设置投放时间段';
        }
        if (item.budget_type == 'lifetime' && !item.end_date) {
          flag = false;
          msg = '设置总预算必须设置结束日期';
        }
        if (this.$store.getters.toFormatedMoney(item.budget).value < 1) {
          flag = false;
          msg = '广告组预算最低不得少于$1';
        }
        if (params.adsets.bid_strategy == 'COST_CAP') {
          if (this.$store.getters.toFormatedMoney(item.bid_amount).value < 1) {
            flag = false;
            msg = '广告组目标费用不得少于$1';
          }
        }
        if (params.adsets.bid_strategy == 'LOWEST_COST_WITH_BID_CAP') {
          if (this.$store.getters.toFormatedMoney(item.bid_amount).value == 0) {
            flag = false;
            msg = '广告组手动出价必填';
          }
        }
        if (params.adsets.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS') {
          if (!item.roas_average_floor) {
            flag = false;
            msg = '广告组最低roas不能为空';
            alert(item.roas_average_floor / 1 > 1000);
          }
          if (item.roas_average_floor / 1 < 0.01 || item.roas_average_floor / 1 > 1000) {
            flag = false;
            msg = '最低roas有效范围【0.01-1000】';
          }
        }
        item.targeting[0] = { ...item.targeting[0] };
      });
      if (!flag) {
        this.$message({
          type: 'warning',
          message: msg,
        });
        return false;
      }

      this.params = { ...params };
      // 给每一个受众加上版位
      this.params.is_upload = true;
      // console.log(this.params);
      this.creatForm = true;
    },
    // 获取缓存的数据
    async getStorygeAdsMsg() {
      // let msg = localStorage.getItem('creatAdsMsg')?JSON.parse(localStorage.getItem('creatAdsMsg'))[this.freshMsgKey]:'';
      // console.log(msg)
      let res = await this.getAdCacheDataFun();
      if (res) {
        this.$confirm('是否使用上次缓存数据？', '提示', {
          confirmButtonText: '使用',
          cancelButtonText: '取消',
          type: 'info',
        }).then(() => {
          let msg = JSON.parse(res);
          this.$refs.adSeries.ruleForm = msg.ruleForm;
          this.$refs.adSeries.targetIndex = msg.ruleForm.targetIndex;
          if (msg.ruleForm.siteUrl) {
            this.$refs.adSeries.onInputWebLink(msg.ruleForm.siteUrl);
          }
          if (msg.ruleForm.site) {
            this.$refs.adSeries.$refs.addSale.remoteMethod(msg.ruleForm.site.domain);
          }
          this.$refs.audience.contentList = msg.contentList;
          this.$refs.audience.setAudiencList();
          this.$refs.ads.adsList = msg.adsList;
          this.$refs.trackCreat.adSet = msg.adSet;
          this.$refs.trackCreat.budget = msg.adSet.budget;
        });
      }
    },
  },
  watch: {
    // 监听广告账户id
    '$store.getters.accountId': function (v, ov) {
      this.accountId = v;
    },
    '$store.getters.fbAccountId': function (v, ov) {
      this.fbAccountId = v;
    },
    activeStep(newVal, oldVal) {
      // 当前步监听
      if (newVal > oldVal) {
        this.setAdCacheDataFun(); // 下一步 缓存 广告数据
      }
      if (this.activeStep != 3) {
        this.stopScroll('创建广告');
        this.setTitleIcon('/favicon.ico');
      }
    },
    hasFiledVideos: {
      handler: function () {
        if (this.hasFiledVideos && this.activeStep == 3) {
          this.handleScroll('素材上传失败，请及时优化或更换素材！！');
          this.setTitleIcon('/favicon-failed.ico');
        } else {
          if (!this.allSuccessdVideos) {
            this.stopScroll('创建广告');
            this.setTitleIcon('/favicon.ico');
          }
        }
      },
      deep: true,
      immediate: true,
    },
    allSuccessdVideos: {
      handler: function () {
        if (this.allSuccessdVideos && this.activeStep == 3 && this.videosList.length > 0) {
          this.handleScroll('素材上传成功，请进行下一步处理！！');
          this.setTitleIcon('/favicon-success.ico');
        } else {
          if (!this.hasFiledVideos) {
            this.stopScroll('创建广告');
            this.setTitleIcon('/favicon.ico');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$refs.trackCreat.editedCampaignName = false;
    // 防止浏览器刷新页面后左侧菜单栏为打开状态
    // console.log('this.$store.state.app.sidebar.opened-1',this.$store.state.app.sidebar.opened)
    let isRefresh = sessionStorage.getItem('isRefresh');
    if (isRefresh) {
      await this.$store.dispatch('app/closeSideBar');
      // console.log('this.$store.state.app.sidebar.opened-2',this.$store.state.app.sidebar.opened)
    }
    window.onunload = (e) => {
      sessionStorage.setItem('isRefresh', 1);
    };

    this.$store.dispatch('app/closeSideBar');
    this.getInitData();
    this.getStorygeAdsMsg(); // 获取缓存数据

    // 检测当前账号状态-禁用时禁止操作
    this.disabedOperate();
  },
  // beforeRouteLeave(to,from,next) {
  //   // console.log("beforeRouteLeave(to,from,next)",to,from,next)
  //   this.$confirm('确认离开该页面吗，离开后已操作数据将丢失, 是否继续?', '提示', {
  //     confirmButtonText: '确定',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     next()
  //   }).catch(() => {
  //     next(false)
  //   });
  // },
  beforeDestroy() {
    this.$refs.trackCreat.editedCampaignName = false;
  },
  destroyed() {
    window.onbeforeunload = null;
    window.onunload = null;
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: 'iconfont';
  /* project id 2194132 */
  src: url('//at.alicdn.com/t/font_2194132_3up75iu0of3.eot');
  src: url('//at.alicdn.com/t/font_2194132_3up75iu0of3.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_2194132_3up75iu0of3.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2194132_3up75iu0of3.woff') format('woff'),
    url('//at.alicdn.com/t/font_2194132_3up75iu0of3.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2194132_3up75iu0of3.svg#iconfont') format('svg');
}

.adsWrap {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .btnGroup {
    margin-top: 30px;
    .creatNew {
      float: right;
      margin-right: 390px;
    }
  }

  .leftLine {
    background: #fff;
    padding: 20px;
    flex: 200px 0 0;
    height: 88vh;
    position: relative;

    .close {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }

  .el-steps--vertical {
    height: 50%;

    .el-step {
      cursor: pointer;
    }
  }

  .rightCon {
    flex: 1;
    // overflow: hidden;
    margin-left: 20px;
    max-height: 88vh;
    // padding: 20px;
    // max-height: 800px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
